<template>
  <v-container class="pa-0">
    <!-- upload -->
    <file-pond
      ref="pond"
      class="pa-0 ma-0"
      accepted-file-types="image/jpeg, image/png"
      :allowMultiple="true"
      :instantUpload="true"
      :allowRemove="false"
      :allowRevert="false"
      maxFileSize="3MB"
      :files="myFiles"
      :server="{ process }"
      :onaddfilestart="fileAdd"
      :onprocessfilestart="fileUploadStart"
      :onprocessfiles="fileUploaded"
      labelIdle="画像をここへドロップ"
      labelFileProcessing="アップロード中..."
      labelTapToCancel=""
      labelFileProcessingComplete="アップロード完了"
    />

    <!-- preview -->
    <v-row v-if="images.length !== 0">
      <v-col
        v-for="image in images"
        :key="image.id"
        class="d-flex child-flex"
        cols="2"
      >
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card>
              <div :class="getSelectedImgStyle(image)">
                <v-img
                  :src="image.src"
                  contain
                  aspect-ratio="1"
                  class="grey lighten-2"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <v-fade-transition>
                  <v-overlay v-if="hover" absolute color="#616161">
                    <v-container fill-height>
                      <v-btn
                        block
                        rounded
                        color="white"
                        outlined
                        @click="onSelectImage(image)"
                        >選択</v-btn
                      >
                      <v-btn
                        block
                        rounded
                        class="mt-4"
                        @click="onSelectDelete(image)"
                        >削除</v-btn
                      >
                    </v-container>
                  </v-overlay>
                </v-fade-transition>
              </div>
            </v-card>
          </template>
        </v-hover>
      </v-col>
    </v-row>

    <!-- dialog -->
    <v-dialog persistent v-model="confirmDialog" max-width="400">
      <v-card>
        <v-card-text>この画像を削除してもよろしいですか？</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="onSelectConfirmCancel()"> キャンセル </v-btn>
          <v-btn
            color="primary"
            text
            @click="onSelectConfirmDelete(delTargetImg)"
          >
            削除する
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// filepond
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
// import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
const FilePond = vueFilePond(
  // FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType
);

import { mapGetters, mapActions } from "vuex";
import {
  uploadFile,
  fetchAllFiles,
  deleteFile,
} from "../../../../service/StorageService";

export default {
  components: {
    FilePond,
  },
  props: {
    storageDirPath: {
      type: String,
      required: true,
    },
    initSelectImgUrl: {
      type: String,
      default: "",
      required: false,
    },
    handleImgSelected: {
      type: Function,
      required: false,
    },
    handleImgDeleted: {
      type: Function,
      required: false,
    },
  },
  data: () => ({
    // upload
    myFiles: [],

    // preview
    images: [],
    tmpUploadedImg: null,
    currentSelected: null,
    confirmDialog: false,
    delTargetImg: null,
  }),
  created: function () {},
  mounted: function () {
    this.loadPreviewImages();
  },
  computed: {
    ...mapGetters(["progress"]),
  },
  watch: {
    currentSelected: function (newValue) {
      if (this.handleImgSelected) {
        this.handleImgSelected(newValue);
      }
    },
  },
  methods: {
    ...mapActions(["showProgress", "hideProgress"]),
    //==============================
    // upload
    //==============================
    /* eslint-disable */
    async process(fieldName, file, metadata, load, error, progress, abort) {
      // console.log("#process start");
      // アップロード
      this.uploadImage(file).then((result) => {
        // result
        // {
        //   "fullPath": "storage のフルパス",
        //   "url": "画像 URL"
        // }
        // console.log("#process completed", result);
        load(result.url); // progress 表示用 (Promise を監視している)

        // fileUploaded で処理するためにこのタイミングで保持
        this.tmpUploadedImg = { src: result.url };
      });
    },
    /* eslint-enable */
    async uploadImage(file) {
      // console.log("# uploadImage", file);
      const fullPath = `${this.storageDirPath}/${file.name}`;
      return uploadFile(fullPath, file);
    },
    fileAdd() {
      // console.log("# fileAdd");
    },
    fileUploadStart() {
      // console.log("# fileUploadStart");
    },
    fileUploaded() {
      // console.log("# fileUploaded");

      // アップロード画像を選択状態にする
      this.currentSelected = this.tmpUploadedImg;

      // preview 部品 reload
      this.loadPreviewImages();
    },
    //==============================
    // preview
    //==============================
    async loadPreviewImages() {
      this.showProgress();
      this.images = [];
      try {
        const storagePath = this.storageDirPath;
        const files = await fetchAllFiles(storagePath);
        const images = files.map((file, index) => {
          return {
            id: String(index), // 表示用(毎回生成)
            src: file.url,
            fullPath: file.fullPath,
          };
        });
        console.log("images", images);

        // 選択画像 取得
        // - 初回は「DB」の画像URL
        // - 一度表示後は「内部保持(自分で選択)」の画像URL
        const selectImgUrl = this.isEmptyImgData(this.currentSelected)
          ? this.initSelectImgUrl
          : this.currentSelected.src;
        // console.log("selectImgUrl", selectImgUrl);
        const targetImage = this.extractTargetImage(images, selectImgUrl);
        // console.log("targetImage", targetImage);
        this.currentSelected = targetImage; // 内部保持

        // this.sleep(300); // 調整

        // 部品に反映
        this.images = images; // 必ず最後に行う
      } catch (e) {
        console.error(e);
      } finally {
        this.hideProgress();
      }
    },
    async delImage(targetImage) {
      // console.log("delImage", targetImage);
      this.showProgress();
      try {
        await deleteFile(targetImage.fullPath);
        if (this.handleImgDeleted) {
          this.handleImgDeleted(targetImage);
        }
        this.loadPreviewImages();
      } catch (e) {
        console.error(e);
      } finally {
        // this.hideProgress(); // load に任せる
      }
    },
    extractTargetImage(images, imgUrl) {
      // URL と同一該当画像を抽出
      if (!images || images.length == 0) {
        return null;
      }
      const targetImage = images.find((image) => image.src === imgUrl);
      // ### 検討 (デフォルトで先頭画像選択)
      // デフォルトで先頭選択すると見た目は設定されたように見える
      // が、DB 登録/更新 するまでは実際に選択状態は保存されていない
      // なのであえて設定されてないように見せる。
      // if (!targetImage) {
      //   return images[0]; // 先頭(default)
      // }
      return targetImage;
    },
    getSelectedImgStyle(image) {
      // console.log("@getSelectedImgStyle", image);
      if (!image || !this.currentSelected) {
        return "pa-2 ";
      }
      if (image.id === this.currentSelected.id) {
        return "pa-2 image-grid--active";
      } else {
        return "pa-2 ";
      }
    },
    onSelectImage(selected) {
      // selected
      // {
      //   "id": "プレビュー部品 index",
      //   "src": "画像 URL",
      //   "fullPath": "storage のフルパス"
      // }
      this.currentSelected = selected;
    },
    onSelectDelete(selected) {
      this.delTargetImg = selected;
      this.confirmDialog = true;
    },
    onSelectConfirmDelete(selected) {
      this.delImage(selected);
      this.confirmDialog = false;
    },
    onSelectConfirmCancel() {
      this.delTargetImg = null;
      this.confirmDialog = false;
    },
    //==============================
    // util
    //==============================
    isEmptyImgData(imgData) {
      // 部品経由すると未選択は空({})で渡される
      return !imgData || !Object.keys(imgData).length;
    },
    async sleep(msec) {
      return new Promise(function (resolve) {
        setTimeout(resolve, msec);
      });
    },
  },
};
</script>

<style lang="sass">
.filepond--panel-root
  background-color: #fafafa
  border: 2px dotted #757575

.vue-select-image__thumbnail--active
  background: #ffab40

.image-grid--active
  background: #ffab40
</style>
